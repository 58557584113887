import './ArticlePage.scss'

import React, { useEffect } from 'react'
import { assertNever } from '@which/shared'

import type { AboutWhichArticleQuery, Author } from '../../generated/frontend'
import { ErrorComponent, PageError } from '../../shared/components/Error'
import { Loader } from '../../shared/components/Loader'
import { Redirect } from '../../shared/components/Redirect'
import { useFullUrl } from '../../shared/hooks/useFullUrl'
import { squirrelUrl } from '../../shared/squirrel-url'
import { PageTemplate } from '../../shared/templates/PageTemplate'
import { usePageProps } from '../../shared/usePageProps'
import { loadScript } from '../../shared/utils/loadScript'
import { removeScriptTag } from '../../shared/utils/remove-script-tag'
import { ToastNotification } from '../reviews/shared/components/ToastNotification'
import type { ArticleBasePageData } from './article-page-types'
import { ArticleTemplateContext } from './ArticleTemplateContext'
import { CompareTrayWrapper } from './components/CompareTrayWrapper/CompareTrayWrapper'
import { dynamicTableDatalayer } from './dynamic-table-datalayer'
import { useArticlePageComponents } from './hooks/useArticlePageComponents'
import { useArticlePageMetaTags } from './hooks/useArticlePageMetaTags'
import { useArticlePageQuery } from './hooks/useArticlePageQuery'

const ArticlePage: React.FC = () => {
  const { getPageMetaTags } = useArticlePageMetaTags()
  const { getPageComponents } = useArticlePageComponents()
  const { loading, error, data } = useArticlePageQuery()

  const isArticleResponse = (
    data: any
  ): data is AboutWhichArticleQuery & { articlePage: { __typename: 'ArticlePage' } } => {
    return data?.articlePage?.__typename === 'ArticlePage'
  }

  const taxonomySlug = isArticleResponse(data) && data?.articlePage?.meta?.taxonomyHierarchy?.category?.slug
    || '';

  const { template: templateName = '', userAccessState } = usePageProps()
  const isPaidMember = ['AUTHENTICATED_FULL_ACCESS', 'FULL_ACCESS'].includes(
    userAccessState?.transformTypeDecision ?? ''
  )

  const { getFullUrl } = useFullUrl()
  
  useEffect(() => {
    const buildUrl = new URL(getFullUrl())
    const isFreeAccountExclusivePage = buildUrl.pathname === '/free-account-exclusive'

    if (
      isFreeAccountExclusivePage &&
      userAccessState &&
      !userAccessState?.userLoggedIn &&
      typeof window !== undefined
    ) {
      window.location.replace(
        `/login?return_url=free-account-exclusive${encodeURIComponent(buildUrl.search)}`
      )
    }
  }, [userAccessState, getFullUrl])

  useEffect(() => {
    const injectSquirrelScript = (
      data?.articlePage as ArticleBasePageData
    )?.articleConfig?.inlinePageScripts?.includes('squirrel')

    const squirrelWidgetScriptUrl = squirrelUrl()

    const loadSquirrelOnPageLoad = () => {
      loadScript({ src: squirrelWidgetScriptUrl })
    }

    if (injectSquirrelScript) {
      if (document.readyState === 'complete') {
        loadSquirrelOnPageLoad()
      } else {
        window.addEventListener('load', loadSquirrelOnPageLoad, false)
        return () => {
          removeScriptTag(squirrelWidgetScriptUrl)
          window.removeEventListener('load', loadSquirrelOnPageLoad)
        }
      }
    }
  }, [data])

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (!data || !data.articlePage) {
    return <PageError pageName="Article Page" />
  }

  const { articlePage } = data

  switch (articlePage.__typename) {
    case 'Redirect':
      return <Redirect to={articlePage.redirectUrl} />
    case 'ArticlePage':
      // Following GQL union pattern for page variants would
      // ease the pain for the following few lines,
      // and we wouldn't need to case articlePage to
      // cover all possible scenarios
      const {
        meta,
        authors,
        tags,
        articleBodyTemplate,
        heroImage,
        articleConfig,
        heroVideo,
        videosSeoData,
        showResubBanner,
      } = articlePage as ArticleBasePageData

      return (
        <ArticleTemplateContext.Provider
          value={{
            meta,
          }}
        >
          <PageTemplate
            components={getPageComponents(articlePage)}
            metaTags={getPageMetaTags({
              meta,
              articleBodyTemplate,
              authors: authors as Author[],
              tags,
              imageList: heroImage?.renditions,
              imageUrl: heroImage?.src,
              twitterCard: 'summary_large_image',
              heroDimensions: heroImage?.dimensions,
              articleConfig,
              heroVideo,
              videosSeoData,
            })}
            showResubBanner={showResubBanner}
            ref={domUpdates}
          />
          {isPaidMember &&
            ['Article Advice Single Page', 'Article Advice Multi Page'].includes(templateName) && (
              <CompareTrayWrapper taxonomySlug={taxonomySlug}/>
            )}
          <ToastNotification />
        </ArticleTemplateContext.Provider>
      )
    default:
      assertNever(articlePage)
  }
}

export default ArticlePage

///////// IMPLEMENTATION /////////

const domUpdates = () => {
  dynamicTableDatalayer()
}
